<template>
  <div>
    <BAlert
      v-if="isBannerNotif && countTotalCard >= minCard"
      show
      variant="warning"
      class="p-2"
    >
      <div class="flex flex-row items-center justify-between gap-2">
        <div>
          <div class="flex items-center gap-3 mb-[10px]">
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
              alt="Komerce"
            >
            <h4 class="text-black font-semibold m-0">
              Gunakan Kompoints-mu!
            </h4>
          </div>
          <BCardText class="text-[#333333]">
            Aktifkan Kompointsmu sekarang untuk bisa digunakan sebagai biaya
            pembuatan kartu.
          </BCardText>
        </div>
        <BButton
          size="sm"
          variant="outline-warning"
          @click="$router.push('/payment-setting')"
        >
          Aktifkan Kompoints
        </BButton>
      </div>
    </BAlert>

    <BCard
      class="mt-[5px]"
      body-class="flex flex-column justify-between"
    >
      <ValidationObserver #default="{ invalid }">
        <BForm>
          <BRow>
            <BCol cols="12">
              <h4 class="text-black font-bold m-0">
                Buat Kartu Baru
              </h4>
              <BRow class="mt-2">
                <BCol cols="12">
                  <BFormGroup>
                    <template #label>
                      <div class="text-black flex items-center">
                        <span class="text-md font-semibold">Label Kartu</span>
                        <span class="text-primary">*</span>
                        <img
                          v-b-popover.hover.top="
                            'Label ini untuk menandai kartu kamu'
                          "
                          src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
                          alt="Komerce"
                          width="20"
                          class="ml-[5px] cursor-pointer"
                        >
                      </div>
                    </template>
                    <ValidationProvider
                      #default="{ errors }"
                      name="Label Kartu"
                      :rules="{required: true, min: 3, max:15}"
                    >
                      <BFormInput
                        v-model="label"
                        placeholder="ex. Pembayaran Ads"
                        :state="errors.length > 0 ? false : null"
                        @keydown="onInput($event)"
                        @paste="onPaste($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </BFormGroup>
                </BCol>
              </BRow>
              <BRow>
                <BCol
                  cols="12"
                  md="6"
                  sm="12"
                  xl="4"
                >
                  <BCard
                    body-class="bg-[#FFECE9] rounded-xl flex flex-column justify-between h-[131px]"
                  >
                    <div class="text-[#F95031] font-semibold text-xl">
                      Saldo Kompay saat ini
                    </div>
                    <BSpinner
                      v-if="loading.balance"
                      variant="primary"
                      medium
                    />
                    <div
                      v-else
                      class="text-[#F95031] font-semibold text-3xl"
                    >
                      <BSpinner
                        v-if="loading.balance"
                        variant="primary"
                        medium
                      />
                      <div
                        v-else
                        class="text-[#F95031] font-semibold text-3xl"
                      >
                        {{ IDR(balanceSummary) }}
                      </div>
                    </div></BCard>
                </BCol>
              </BRow>
              <BRow>
                <BCol cols="12">
                  <BFormGroup>
                    <template #label>
                      <div class="text-black flex items-center">
                        <span
                          class="text-md font-semibold"
                        >Top Up Saldo Awal Komcard</span>
                        <span class="text-primary">*</span>
                        <img
                          v-b-popover.hover.top="
                            'saldo kartu komcards diambil dari saldo kompay'
                          "
                          src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
                          alt="Komerce"
                          width="20"
                          class="ml-[5px] cursor-pointer"
                        >
                      </div>
                    </template>
                    <ValidationProvider
                      #default="{ errors }"
                      name="Top Up Saldo Komcard"
                      :rules="{
                        required: true,
                        minAmountCreate: 'add',
                        maxAmount: amountMaxCreate,
                      }"
                    >
                      <BFormInput
                        v-model="balance"
                        placeholder="Min. Rp 20.000"
                        :state="errors.length > 0 ? false : null"
                        @keypress="isNumber($event)"
                        @input="formatCurrency"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </BFormGroup>
                </BCol>
              </BRow>
              <!-- <BRow>
                <BCol
                  cols="12"
                  md="12"
                  sm="12"
                >
                  <BButton
                    v-for="item in listTopUp"
                    :key="item.id"
                    :variant="activeTopup === item.id ? 'outline-primary' : 'outline-secondary'"
                    class="mr-1 mt-[5px]"
                    :disabled="formatCurrency(balance)"
                    @click="active(item)"
                  >
                    <span class="font-semibold">{{ IDR(item.balance) }}</span>
                  </BButton>
                </BCol>
              </BRow> -->
            </BCol>
          </BRow>
          <BRow class="mt-1">
            <BCol
              lg="4"
              offset-lg="6"
              class="text-[#828282]"
            >
              Top Up Saldo Awal
            </BCol>
            <BCol
              class="d-flex justify-start sm:justify-end text-black"
              lg="2"
            >
              {{ balance === '' ? IDR(0) : balance }}
            </BCol>
          </BRow>
          <BRow class="my-1">
            <BCol
              lg="4"
              offset-lg="6"
              class="text-[#828282]"
            >
              Biaya Pembuatan Kartu
            </BCol>
            <BCol
              class="d-flex justify-start sm:justify-end text-black"
              lg="2"
            >
              {{ IDR(feeCard) }}
            </BCol>
          </BRow>
          <BRow
            v-if="!isBannerNotif"
            class="my-1"
          >
            <BCol
              lg="4"
              offset-lg="6"
              class="text-[#828282]"
            >
              <div class="flex items-center gap-2">
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
                  alt="Komerce"
                  width="20"
                >
                <span>Gunakan Kompoints</span>
                <BFormCheckbox
                  v-model="usedKompoints"
                  switch
                />
              </div>
            </BCol>
            <BCol
              class="d-flex justify-start sm:justify-end text-black"
              lg="2"
            >
              <div class="flex items-center gap-2">
                <span
                  :class="usedKompoints ? 'text-orange-400' : 'text-gray-500'"
                >-{{
                  IDRWithoutLbl(kompoints.setting.komcard_card_fee)
                }}</span>
                <img
                  v-b-popover.hover.top="
                    'Maksimal Kompoints yang dapat digunakan adalah 20.000 dan digunakan untuk membayar biaya pembuatan kartu'
                  "
                  src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
                  class="cursor-pointer"
                  alt="Komerce"
                  width="20"
                >
              </div>
            </BCol>
          </BRow>
          <BRow>
            <BCol
              lg="6"
              offset-lg="6"
            >
              <hr class="mt-0">
            </BCol>
          </BRow>
          <BRow>
            <BCol
              lg="4"
              offset-lg="6"
              class="text-black text-xl"
            >
              Total yang harus dibayarkan
            </BCol>
            <BCol
              class="d-flex justify-start sm:justify-end text-primary text-xl"
              lg="2"
            >
              {{ totalPayment === '' ? IDR(0) : totalPayment }}
            </BCol>
          </BRow>
          <BRow
            v-if="isPossibilityTopup"
            class="mt-1"
          >
            <BCol
              lg="4"
              offset-lg="6"
              class="text-[#828282] text-xl flex items-center"
            >
              <span>Saldo Ideal Kompay</span>
              <BPopover
                triggers="hover"
                target="posibilityTooltip"
                placement="topLeft"
              >
                Jumlah Ongkir Retur -({{ IDR(possiblility.potency_retur) }})
                dikurangi jumlah potensi pendapatan dikirim +({{
                  IDR(possiblility.potency_income)
                }})
              </BPopover>
              <img
                id="posibilityTooltip"
                src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
                alt="Komerce"
                class="ml-[10px] w-[20px] cursor-pointer"
              >
            </BCol>
            <BCol
              class="d-flex justify-start sm:justify-end text-xl"
              lg="2"
            >
              {{ IDR(possiblility.ideal_balance) }}
            </BCol>
          </BRow>
          <BRow class="my-1 justify-end">
            <BCol
              lg="4"
              offset-lg="8"
              class="text-black text-xl"
            />
            <BCol
              class="d-flex justify-start sm:justify-end text-primary text-xl"
              lg="4"
            >
              <BButton
                variant="outline-primary"
                class="mr-1"
                @click="$router.go(-1)"
              >
                Batal
              </BButton>
              <BButton
                :variant="
                  invalid || disabled || loading.possible || isPossibilityTopup
                    ? 'secondary'
                    : 'primary'
                "
                :disabled="
                  invalid || disabled || loading.possible || isPossibilityTopup
                "
                @click="onCreate()"
              >
                <BSpinner
                  v-if="loading.possible"
                  small
                />
                Buat Kartu
              </BButton>
            </BCol>
          </BRow>
        </BForm>
      </ValidationObserver>
    </BCard>
    <ModalConfirmation
      :payload-create="payload"
      :source="source"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required, numeric, min, max,
} from '@validations'
import { IDR, IDRWithoutLbl } from '@/libs/currency'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import { isNumber } from '@/libs/helpers'
import isNaN from 'lodash/isNaN'
import { listTopUp } from './config'
import ModalConfirmation from './components/ModalConfirmation.vue'

extend('minAmountCreate', {
  validate: value => {
    const minAmountCreate = 20000
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))

    return amount >= minAmountCreate
  },
  message: () => {
    const minAmountCreate = 20000
    return `Minimal ${IDR(minAmountCreate)}`
  },
})

extend('maxAmount', {
  validate: (value, args) => {
    const maxAmount = args[0]
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount <= maxAmount
  },
  message: (field, args) => {
    const maxAmount = args[0]
    return `Maaf, maksimal top up saldo yang bisa kamu lakukan sebesar ${IDR(
      maxAmount,
    )}`
  },
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ModalConfirmation,
  },
  data() {
    return {
      IDR,
      IDRWithoutLbl,
      required,
      numeric,
      min,
      max,
      label: '',
      balance: '',
      listTopUp,
      activeTopup: null,
      user: this.$store.state.auth.userData,
      balanceSummary: 0,
      countTotalCard: this.$route.query.count,
      minCardMessage:
        'Kamu telah membuat 5 kartu komcards. Biaya pembuatan kartu berikutnya adalah Rp20.000/kartu dengan saldo awal minimal Rp. 20.000. Periksa saldo kompaymu sebelum melanjutkan proses selanjutnya!',
      minCard: 5,
      isNumber,
      isNaN,
      disabled: false,
      loading: {
        balance: false,
        possible: false,
        kompoints: false,
      },
      source: null,
      possiblility: {},
      possibleCreate: false,
      kompoints: {},
      usedKompoints: false,
      amountMaxCreate: 0,
    }
  },
  computed: {
    feeCard() {
      return this.countTotalCard >= this.minCard ? 20000 : ''
    },
    totalPayment() {
      const formatedBalance = Number(this.balance.replace(/\D/g, ''))
      const cardFee = this.feeCard === '' ? 0 : Number(this.feeCard)
      const kompointsFee = this.usedKompoints
        ? this.kompoints.setting.komcard_card_fee
        : 0
      return IDR(formatedBalance + cardFee - kompointsFee)
    },
    payload() {
      return {
        label: this.label,
        balance: Number(this.balance.replace(/[^0-9]/g, '')),
        card_fee: this.feeCard === '' ? 0 : Number(this.feeCard),
        card_fee_kompoints: this.usedKompoints
          ? this.kompoints.setting.komcard_card_fee
          : 0,
      }
    },
    active() {
      return value => {
        const { id, balance } = value
        this.balance = IDR(balance)
        this.activeTopup = id
        this.disabled = false
        this.possibleCreate = false
      }
    },
    formatCurrency() {
      return value => {
        const newValue = Number(value.replace(/\D/g, ''))
        this.balance = isNaN(newValue) ? value : IDR(newValue)
        this.activeTopup = null

        if (this.user.is_komship === 1) {
          if (this.usedKompoints) {
            if (this.possiblility.ideal_balance >= 0) {
              // ada saldo ideal
              const amountMaxCreate = this.possiblility.balance
                - (this.feeCard - this.kompoints.setting.komcard_card_fee)
                - this.possiblility.ideal_balance
              this.amountMaxCreate = amountMaxCreate
            }
            if (this.possiblility.ideal_balance <= 0) {
              // tidak ada saldo ideal
              const amountMaxCreate = this.possiblility.balance
                - (this.feeCard - this.kompoints.setting.komcard_card_fee)
              this.amountMaxCreate = amountMaxCreate
            }
          }

          if (!this.usedKompoints) {
            if (this.possiblility.ideal_balance >= 0) {
              // ada saldo ideal
              const amountMaxCreate = this.possiblility.balance
                - this.feeCard
                - this.possiblility.ideal_balance
              this.amountMaxCreate = amountMaxCreate
            }
            if (this.possiblility.ideal_balance <= 0) {
              // tidak ada saldo ideal
              const amountMaxCreate = this.possiblility.balance - this.feeCard
              this.amountMaxCreate = amountMaxCreate
            }
          }
          return
        }

        if (this.usedKompoints) {
          const amountMaxCreate = this.balanceSummary
            - (this.feeCard - this.kompoints.setting.komcard_card_fee)
          this.amountMaxCreate = amountMaxCreate
        }
        if (!this.usedKompoints) {
          const amountMaxCreate = this.balanceSummary - this.feeCard
          this.amountMaxCreate = amountMaxCreate
        }
      }
    },
    isBannerNotif() {
      return !this.kompoints.is_active
    },
    isPossibilityTopup() {
      return this.possibleCreate && !this.usedKompoints
    },
  },
  watch: {
    usedKompoints() {
      return this.formatCurrency(this.balance)
    },
  },
  mounted() {
    this.getBalance()
    if (Number(this.countTotalCard) >= this.minCard) {
      this.getKompoints()
    }
    if (this.user.is_komship === 1) {
      this.checkPossible()
    }
  },
  methods: {
    onInput(event) {
      const isLetter = (event.key >= 'a' && event.key <= 'z') || (event.key >= 'A' && event.key <= 'Z')
      const numberOnly = event.key >= '0' && event.key <= '9'
      const isControlKey = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Space'].includes(event.key)

      if (!(isLetter || numberOnly || isControlKey || event.key === ' ')) {
        event.preventDefault()
      }
    },
    onPaste(event) {
      // Get pasted data
      const pastedText = (event.clipboardData || window.clipboardData).getData('text')
      // if pasted text is not a number or a letter
      if (!/^[a-zA-Z0-9\s]+$/.test(pastedText)) {
        event.preventDefault()
      }
    },
    async getBalance() {
      this.loading.balance = true
      const url = `/v1/dashboard/partner/balanceSummary?partner_id=${this.user.id}`
      await komshipAxiosIns.get(url).then(res => {
        const {
          data: { balance },
        } = res.data
        this.balanceSummary = balance
        this.loading.balance = false
      })
    },
    async checkPossible() {
      this.loading.possible = true
      const url = `/v1/partner/withdrawal/check-possible-withdraw?withdrawal_request_nominal=${Number(
        this.balance.replace(/\D/g, ''),
      )}`
      await komshipAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          const { ideal_balance } = data
          const topup = Number(this.balance.replace(/[^0-9]/g, ''))

          this.possibleCreate = ideal_balance < topup
          this.possiblility = data
          this.loading.possible = false
        })
        .catch(() => {
          this.loading.possible = false
          this.$toast_error({ message: 'Gagal memuat data check withdrawal' })
        })
    },
    async getKompoints() {
      this.loading.kompoints = true
      const url = '/auth/api/v1/user/kompoints'
      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.kompoints = data
          this.loading.kompoints = false
        })
        .catch(() => {
          this.loading.kompoints = false
          this.$toast_error({ message: 'Gagal mendapatkan kompoints' })
        })
    },
    onCreate() {
      this.$bvModal.show('modal-confirmation')
      this.source = 'create'
    },
  },
}
</script>
<style scoped>
.alert-warning {
  background: #f8ecc8 !important;
}
</style>
